<template>
  <div id="radaroptions">
    <h4>Left Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radar.offsetX"
        :max="100"
        :min="-100"
        show-input
      ></el-slider>
    </div>

    <h4>Top Offset</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.radar.offsetY"
        :max="100"
        :min="-100"
        show-input
      ></el-slider>
    </div>

    <el-collapse class="mt3">
      <el-collapse-item title="Polygon">
        <el-form-item class="custom-colors-picker" label="Stroke Colors">
          <extended-el-color-picker
            @change="updateChart"
            v-model="plotOptions.radar.polygons.strokeColors"
          ></extended-el-color-picker>
        </el-form-item>

        <el-form-item class="custom-colors-picker" label="Connector Colors">
          <extended-el-color-picker
            @change="updateChart"
            v-model="plotOptions.radar.polygons.connectorColors"
          ></extended-el-color-picker>
        </el-form-item>

        <el-form-item class="custom-colors-picker" label="Fill Colors">
          <extended-el-color-picker
            v-for="(color, index) in plotOptions.radar.polygons.fill.colors"
            :key="index"
            @change="
              (val) => {
                updateFillColors(val, index);
              }
            "
            :value="color"
          ></extended-el-color-picker>
          <el-button @click="appendFillColor" size="mini">
            <i class="el-icon-plus"></i>
          </el-button>
          <el-button
            @click="clearAllFillColors"
            size="mini"
            title="Delete All Colors"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        radar: {
          offsetX: 0,
          offsetY: 0,
          polygons: {
            // strokeColor: '#e8e8e8', // should be deprecated in the minor version i.e 3.2
            strokeColors: "#e8e8e8",
            connectorColors: "#e8e8e8",
            fill: {
              colors: undefined,
            },
          },
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions;
      this.setNewOptions(p);
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        radar: {
          offsetX: p.radar.offsetX,
          offsetY: p.radar.offsetY,
          polygons: {
            strokeColors: p.radar.polygons.strokeColors,
            connectorColors: p.radar.polygons.connectorColors,
            fill: {
              colors: p.radar.polygons.fill.colors,
            },
          },
        },
      };
    },
    updateFillColors(color, index) {
      Vue.set(this.plotOptions.radar.polygons.fill.colors, index, color);
      this.updateChart();
    },

    appendFillColor() {
      if (!this.plotOptions.radar.polygons.fill.colors) {
        this.plotOptions.radar.polygons.fill.colors = [];
      }
      this.plotOptions.radar.polygons.fill.colors.push("#f1f2f3");
      this.updateChart();
    },

    clearAllFillColors: function() {
      this.plotOptions.radar.polygons.fill.colors = undefined;
      this.updateChart();
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        plotOptions: {
          radar: {
            offsetX: p.radar.offsetX,
            offsetY: p.radar.offsetY,
            polygons: {
              strokeColors: p.radar.polygons.strokeColors,
              connectorColors: p.radar.polygons.connectorColors,
              fill: {
                colors: p.radar.polygons.fill.colors,
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
